import { ReactNode, useState } from "react"

const API = process.env.REACT_APP_BACKEND

const App = () => {
  const [title, setTitle] = useState<string>("edit title")
  const [text, setText] = useState<string>("edit text")

  const submit = async () => {
    const resp = await fetch(`${API}/submit`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title,
        text,
      }),
    })

    console.log({ resp })
    return
  }

  return (
    <Container>
      <article className="prose prose-stone prose-a:text-blue-600">
        <h1>
          <input
            type="text"
            placeholder="enter title..."
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="bg-transparent rounded outline-0 h-full"
          />
        </h1>
        <p>
          <textarea
            placeholder="start entering text here..."
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="bg-yellow-200 rounded outline-0 w-full resize-none"
          />
        </p>
      </article>
      <button
        type="button"
        className="bg-blue-800 text-white rounded w-20"
        onClick={submit}
      >
        submit
      </button>
    </Container>
  )
}

export default App

const Container = ({ children }: IChildren) => (
  <div className="bg-yellow-100">
    <div className="container mx-auto h-screen">
      <div className="flex justify-center">
        <div className="w-96 pt-8">{children}</div>
      </div>
    </div>
  </div>
)

interface IChildren {
  children: ReactNode
}
